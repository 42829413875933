import createLucideIcon from '../createLucideIcon';

/**
 * @component @name MousePointerBan
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMi4wMzQgMi42ODFhLjQ5OC40OTggMCAwIDEgLjY0Ny0uNjQ3bDkgMy41YS41LjUgMCAwIDEtLjAzMy45NDRMOC4yMDQgNy41NDVhMSAxIDAgMCAwLS42Ni42NmwtMS4wNjYgMy40NDNhLjUuNSAwIDAgMS0uOTQ0LjAzM3oiIC8+CiAgPGNpcmNsZSBjeD0iMTYiIGN5PSIxNiIgcj0iNiIgLz4KICA8cGF0aCBkPSJtMTEuOCAxMS44IDguNCA4LjQiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/mouse-pointer-ban
 * @see https://lucide.dev/guide/packages/lucide-vue-next - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {FunctionalComponent} Vue component
 *
 */
const MousePointerBan = createLucideIcon('MousePointerBanIcon', [
  [
    'path',
    {
      d: 'M2.034 2.681a.498.498 0 0 1 .647-.647l9 3.5a.5.5 0 0 1-.033.944L8.204 7.545a1 1 0 0 0-.66.66l-1.066 3.443a.5.5 0 0 1-.944.033z',
      key: '11pp1i',
    },
  ],
  ['circle', { cx: '16', cy: '16', r: '6', key: 'qoo3c4' }],
  ['path', { d: 'm11.8 11.8 8.4 8.4', key: 'oogvdj' }],
]);

export default MousePointerBan;
