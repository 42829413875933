import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Omega
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMyAyMGg0LjVhLjUuNSAwIDAgMCAuNS0uNXYtLjI4MmEuNTIuNTIgMCAwIDAtLjI0Ny0uNDM3IDggOCAwIDEgMSA4LjQ5NC0uMDAxLjUyLjUyIDAgMCAwLS4yNDcuNDM4di4yODJhLjUuNSAwIDAgMCAuNS41SDIxIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/omega
 * @see https://lucide.dev/guide/packages/lucide-vue-next - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {FunctionalComponent} Vue component
 *
 */
const Omega = createLucideIcon('OmegaIcon', [
  [
    'path',
    {
      d: 'M3 20h4.5a.5.5 0 0 0 .5-.5v-.282a.52.52 0 0 0-.247-.437 8 8 0 1 1 8.494-.001.52.52 0 0 0-.247.438v.282a.5.5 0 0 0 .5.5H21',
      key: '1x94xo',
    },
  ],
]);

export default Omega;
