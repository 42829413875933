import createLucideIcon from '../createLucideIcon';

/**
 * @component @name ChartColumnBig
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMyAzdjE2YTIgMiAwIDAgMCAyIDJoMTYiIC8+CiAgPHJlY3QgeD0iMTUiIHk9IjUiIHdpZHRoPSI0IiBoZWlnaHQ9IjEyIiByeD0iMSIgLz4KICA8cmVjdCB4PSI3IiB5PSI4IiB3aWR0aD0iNCIgaGVpZ2h0PSI5IiByeD0iMSIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/chart-column-big
 * @see https://lucide.dev/guide/packages/lucide-vue-next - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {FunctionalComponent} Vue component
 *
 */
const ChartColumnBig = createLucideIcon('ChartColumnBigIcon', [
  ['path', { d: 'M3 3v16a2 2 0 0 0 2 2h16', key: 'c24i48' }],
  ['rect', { x: '15', y: '5', width: '4', height: '12', rx: '1', key: 'q8uenq' }],
  ['rect', { x: '7', y: '8', width: '4', height: '9', rx: '1', key: 'sr5ea' }],
]);

export default ChartColumnBig;
