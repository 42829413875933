import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Bandage
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTAgMTAuMDFoLjAxIiAvPgogIDxwYXRoIGQ9Ik0xMCAxNC4wMWguMDEiIC8+CiAgPHBhdGggZD0iTTE0IDEwLjAxaC4wMSIgLz4KICA8cGF0aCBkPSJNMTQgMTQuMDFoLjAxIiAvPgogIDxwYXRoIGQ9Ik0xOCA2djExLjUiIC8+CiAgPHBhdGggZD0iTTYgNnYxMiIgLz4KICA8cmVjdCB4PSIyIiB5PSI2IiB3aWR0aD0iMjAiIGhlaWdodD0iMTIiIHJ4PSIyIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/bandage
 * @see https://lucide.dev/guide/packages/lucide-vue-next - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {FunctionalComponent} Vue component
 *
 */
const Bandage = createLucideIcon('BandageIcon', [
  ['path', { d: 'M10 10.01h.01', key: '1e9xi7' }],
  ['path', { d: 'M10 14.01h.01', key: 'ac23bv' }],
  ['path', { d: 'M14 10.01h.01', key: '2wfrvf' }],
  ['path', { d: 'M14 14.01h.01', key: '8tw8yn' }],
  ['path', { d: 'M18 6v11.5', key: 'dkbidh' }],
  ['path', { d: 'M6 6v12', key: 'vkc79e' }],
  ['rect', { x: '2', y: '6', width: '20', height: '12', rx: '2', key: '1wpnh2' }],
]);

export default Bandage;
