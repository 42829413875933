import createLucideIcon from '../createLucideIcon';

/**
 * @component @name ArrowBigLeft
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTggMTVoLTZ2NGwtNy03IDctN3Y0aDZ2NnoiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/arrow-big-left
 * @see https://lucide.dev/guide/packages/lucide-vue-next - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {FunctionalComponent} Vue component
 *
 */
const ArrowBigLeft = createLucideIcon('ArrowBigLeftIcon', [
  ['path', { d: 'M18 15h-6v4l-7-7 7-7v4h6v6z', key: 'lbrdak' }],
]);

export default ArrowBigLeft;
