import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Navigation2Off
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNOS4zMSA5LjMxIDUgMjFsNy00IDcgNC0xLjE3LTMuMTciIC8+CiAgPHBhdGggZD0iTTE0LjUzIDguODggMTIgMmwtMS4xNyAzLjE3IiAvPgogIDxsaW5lIHgxPSIyIiB4Mj0iMjIiIHkxPSIyIiB5Mj0iMjIiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/navigation-2-off
 * @see https://lucide.dev/guide/packages/lucide-vue-next - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {FunctionalComponent} Vue component
 *
 */
const Navigation2Off = createLucideIcon('Navigation2OffIcon', [
  ['path', { d: 'M9.31 9.31 5 21l7-4 7 4-1.17-3.17', key: 'qoq2o2' }],
  ['path', { d: 'M14.53 8.88 12 2l-1.17 3.17', key: 'k3sjzy' }],
  ['line', { x1: '2', x2: '22', y1: '2', y2: '22', key: 'a6p6uj' }],
]);

export default Navigation2Off;
