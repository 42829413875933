import createLucideIcon from '../createLucideIcon';

/**
 * @component @name FileVolume
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTEgMTFhNSA1IDAgMCAxIDAgNiIgLz4KICA8cGF0aCBkPSJNMTQgMnY0YTIgMiAwIDAgMCAyIDJoNCIgLz4KICA8cGF0aCBkPSJNNCA2Ljc2NVY0YTIgMiAwIDAgMSAyLTJoOWw1IDV2MTNhMiAyIDAgMCAxLTIgMkg2YTIgMiAwIDAgMS0uOTMtLjIzIiAvPgogIDxwYXRoIGQ9Ik03IDEwLjUxYS41LjUgMCAwIDAtLjgyNi0uMzhsLTEuODkzIDEuNjI4QTEgMSAwIDAgMSAzLjYzIDEySDIuNWEuNS41IDAgMCAwLS41LjV2M2EuNS41IDAgMCAwIC41LjVoMS4xMjlhMSAxIDAgMCAxIC42NTIuMjQybDEuODkzIDEuNjNhLjUuNSAwIDAgMCAuODI2LS4zOHoiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/file-volume
 * @see https://lucide.dev/guide/packages/lucide-vue-next - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {FunctionalComponent} Vue component
 *
 */
const FileVolume = createLucideIcon('FileVolumeIcon', [
  ['path', { d: 'M11 11a5 5 0 0 1 0 6', key: '193qb2' }],
  ['path', { d: 'M14 2v4a2 2 0 0 0 2 2h4', key: 'tnqrlb' }],
  [
    'path',
    { d: 'M4 6.765V4a2 2 0 0 1 2-2h9l5 5v13a2 2 0 0 1-2 2H6a2 2 0 0 1-.93-.23', key: 'ifyjnl' },
  ],
  [
    'path',
    {
      d: 'M7 10.51a.5.5 0 0 0-.826-.38l-1.893 1.628A1 1 0 0 1 3.63 12H2.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h1.129a1 1 0 0 1 .652.242l1.893 1.63a.5.5 0 0 0 .826-.38z',
      key: 'mk8rxu',
    },
  ],
]);

export default FileVolume;
