import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Rewind
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cG9seWdvbiBwb2ludHM9IjExIDE5IDIgMTIgMTEgNSAxMSAxOSIgLz4KICA8cG9seWdvbiBwb2ludHM9IjIyIDE5IDEzIDEyIDIyIDUgMjIgMTkiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/rewind
 * @see https://lucide.dev/guide/packages/lucide-vue-next - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {FunctionalComponent} Vue component
 *
 */
const Rewind = createLucideIcon('RewindIcon', [
  ['polygon', { points: '11 19 2 12 11 5 11 19', key: '14yba5' }],
  ['polygon', { points: '22 19 13 12 22 5 22 19', key: '1pi1cj' }],
]);

export default Rewind;
