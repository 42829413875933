import createLucideIcon from '../createLucideIcon';

/**
 * @component @name ReceiptText
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNCAydjIwbDItMSAyIDEgMi0xIDIgMSAyLTEgMiAxIDItMSAyIDFWMmwtMiAxLTItMS0yIDEtMi0xLTIgMS0yLTEtMiAxWiIgLz4KICA8cGF0aCBkPSJNMTQgOEg4IiAvPgogIDxwYXRoIGQ9Ik0xNiAxMkg4IiAvPgogIDxwYXRoIGQ9Ik0xMyAxNkg4IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/receipt-text
 * @see https://lucide.dev/guide/packages/lucide-vue-next - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {FunctionalComponent} Vue component
 *
 */
const ReceiptText = createLucideIcon('ReceiptTextIcon', [
  [
    'path',
    { d: 'M4 2v20l2-1 2 1 2-1 2 1 2-1 2 1 2-1 2 1V2l-2 1-2-1-2 1-2-1-2 1-2-1-2 1Z', key: 'q3az6g' },
  ],
  ['path', { d: 'M14 8H8', key: '1l3xfs' }],
  ['path', { d: 'M16 12H8', key: '1fr5h0' }],
  ['path', { d: 'M13 16H8', key: 'wsln4y' }],
]);

export default ReceiptText;
