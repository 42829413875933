import createLucideIcon from '../createLucideIcon';

/**
 * @component @name SquareDashedMousePointer
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTIuMDM0IDEyLjY4MWEuNDk4LjQ5OCAwIDAgMSAuNjQ3LS42NDdsOSAzLjVhLjUuNSAwIDAgMS0uMDMzLjk0M2wtMy40NDQgMS4wNjhhMSAxIDAgMCAwLS42Ni42NmwtMS4wNjcgMy40NDNhLjUuNSAwIDAgMS0uOTQzLjAzM3oiIC8+CiAgPHBhdGggZD0iTTUgM2EyIDIgMCAwIDAtMiAyIiAvPgogIDxwYXRoIGQ9Ik0xOSAzYTIgMiAwIDAgMSAyIDIiIC8+CiAgPHBhdGggZD0iTTUgMjFhMiAyIDAgMCAxLTItMiIgLz4KICA8cGF0aCBkPSJNOSAzaDEiIC8+CiAgPHBhdGggZD0iTTkgMjFoMiIgLz4KICA8cGF0aCBkPSJNMTQgM2gxIiAvPgogIDxwYXRoIGQ9Ik0zIDl2MSIgLz4KICA8cGF0aCBkPSJNMjEgOXYyIiAvPgogIDxwYXRoIGQ9Ik0zIDE0djEiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/square-dashed-mouse-pointer
 * @see https://lucide.dev/guide/packages/lucide-vue-next - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {FunctionalComponent} Vue component
 *
 */
const SquareDashedMousePointer = createLucideIcon('SquareDashedMousePointerIcon', [
  [
    'path',
    {
      d: 'M12.034 12.681a.498.498 0 0 1 .647-.647l9 3.5a.5.5 0 0 1-.033.943l-3.444 1.068a1 1 0 0 0-.66.66l-1.067 3.443a.5.5 0 0 1-.943.033z',
      key: 'xwnzip',
    },
  ],
  ['path', { d: 'M5 3a2 2 0 0 0-2 2', key: 'y57alp' }],
  ['path', { d: 'M19 3a2 2 0 0 1 2 2', key: '18rm91' }],
  ['path', { d: 'M5 21a2 2 0 0 1-2-2', key: 'sbafld' }],
  ['path', { d: 'M9 3h1', key: '1yesri' }],
  ['path', { d: 'M9 21h2', key: '1qve2z' }],
  ['path', { d: 'M14 3h1', key: '1ec4yj' }],
  ['path', { d: 'M3 9v1', key: '1r0deq' }],
  ['path', { d: 'M21 9v2', key: 'p14lih' }],
  ['path', { d: 'M3 14v1', key: 'vnatye' }],
]);

export default SquareDashedMousePointer;
