import createLucideIcon from '../createLucideIcon';

/**
 * @component @name AlignJustify
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMyAxMmgxOCIgLz4KICA8cGF0aCBkPSJNMyAxOGgxOCIgLz4KICA8cGF0aCBkPSJNMyA2aDE4IiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/align-justify
 * @see https://lucide.dev/guide/packages/lucide-vue-next - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {FunctionalComponent} Vue component
 *
 */
const AlignJustify = createLucideIcon('AlignJustifyIcon', [
  ['path', { d: 'M3 12h18', key: '1i2n21' }],
  ['path', { d: 'M3 18h18', key: '1h113x' }],
  ['path', { d: 'M3 6h18', key: 'd0wm0j' }],
]);

export default AlignJustify;
